<template>
	<div class="play">
		<TransitionGroup name="fade" appear>
			<h2 :key="1">{{ translate('play') }}</h2>
			<p :key="2" class="subtitle">{{ translate('play.subtitle') }}</p>
		</TransitionGroup>

		<Quizzes />
	</div>
</template>

<script>
import Quizzes from '@/components/Quizzes.vue'

export default {
	name: 'Home',
	components: {
		Quizzes,
	},
}
</script>

<style lang="scss" scoped>
.play {
	text-align: center;
}
</style>
