<template>
	<div id="quizzes">
		<div v-if="loaded" class="quizzes">
			<Transition name="fade-slide-up" appear>
				<div v-if="showEmptyNotice" class="alert text-center extra-padding full-width">
					<div>
						<p>{{ translate('quizHistory.notice.noQuizzes.playedAll') }}</p>
						<button
							class="primary icon-right small"
							style="margin-top: 20px"
							@click="$router.push('/builder')">
							{{ translate('quizHistory.notice.noQuizzes.playedAll.createOne') }}
							<Icon name="arrow-forward" fill="white" width="20" />
						</button>
					</div>
				</div>
			</Transition>

			<TransitionGroup appear @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter">
				<div
					v-for="(quiz, index) in quizzes"
					:key="quiz.id"
					class="quiz"
					:data-index="index"
					@click="$router.replace(`/q/${quiz.qid}`)">
					<transition name="fade">
						<div v-if="quiz.cover_url" class="cover-img">
							<img :src="quiz.cover_url" alt="Cover Image" />
						</div>
					</transition>
					<p class="date">{{ formatDate(quiz.published_at) }}</p>
					<User :id="quiz.created_by" :only-avatar="true" :clickable="false" />
					<p class="title">{{ quiz.title }}</p>
					<div class="action">
						<button class="small">
							{{ quiz.started ? translate('continue') : translate('play') }}
						</button>
					</div>
				</div>
			</TransitionGroup>
		</div>

		<Loader v-if="!loaded" />
	</div>
</template>

<script>
import { auth, db } from '@/lib/supabase'
import { handleError } from '@/utils/utils'
import gsap from 'gsap'

import Loader from '@/components/Loader.vue'
import User from '@/components/User.vue'
import Icon from './Icon.vue'

export default {
	components: {
		Loader,
		User,
		Icon,
	},

	data() {
		return {
			quizzes: [],
			loaded: false,
			order: 'date',
			showEmptyNotice: false,
			subscriptionQuizzes: undefined,
		}
	},

	async mounted() {
		await this.getQuizzes()
		this.subscribeQuizzes()
	},

	destroyed() {
		db.removeSubscription(this.subscriptionQuizzes)
	},

	methods: {
		async getQuizzes() {
			const { data: quizzes, error } = await db.rpc('get_available_quizzes', {
				user_id_param: auth.user().id,
			})

			if (quizzes) {
				this.quizzes = quizzes
				this.loaded = true
				if (quizzes.length === 0) this.showEmptyNotice = true
			} else {
				handleError(error)
			}
		},

		subscribeQuizzes() {
			this.subscriptionQuizzes = db
				.from('quizzes')
				.on('UPDATE', (payload) => {
					const quiz = payload.new
					const existingQuiz = this.quizzes.findIndex((q) => q.id === quiz.id)

					if (quiz.published_at) {
						if (existingQuiz !== -1) {
							this.$set(this.quizzes, existingQuiz, quiz)
						} else {
							this.quizzes.push(quiz)
							this.quizzes.sort((a, b) => new Date(b.published_at) - new Date(a.published_at))
						}
					} else {
						if (existingQuiz !== -1) {
							this.quizzes.splice(existingQuiz, 1)
						}
					}
				})
				.subscribe()
		},

		formatDate(timestamp) {
			return new Date(timestamp).toLocaleDateString(this.$store.getters.locale, {
				month: 'short',
				day: 'numeric',
			})
		},

		beforeEnter(el) {
			el.style.opacity = 0
			el.style.transform = 'translateY(30px)'
		},

		enter(el, done) {
			gsap.to(el, {
				opacity: 1,
				y: 0,
				duration: 0.8,
				onComplete: done,
				delay: el.dataset.index * 0.1,
			})
		},

		afterEnter(el) {
			el.style.transition = '.25s ease'
		},
	},
}
</script>

<style lang="scss" scoped>
#quizzes {
	margin-top: 50px;

	.top {
		align-items: center;
		display: flex;

		.select {
			align-items: center;
			display: flex;
			margin: 0 0 0 auto;
			opacity: 0.5;

			label {
				font-size: 14px;
				margin-bottom: 0;
				position: static;
				transform: none;
			}

			select {
				font-size: 14px;
				padding: 0 25px 1px 10px;
			}
		}
	}

	.quizzes {
		margin-top: 10px;

		.quiz {
			align-items: center;
			background: darken(white, 5%);
			border-radius: $border-radius;
			cursor: pointer;
			display: flex;
			padding: 20px 30px;
			position: relative;

			@include mobile {
				flex-wrap: wrap;
				padding: 10px 15px;

				.title {
					font-size: 16px;
					margin-top: 5px;
					padding-bottom: 5px;
					order: 4;
				}
			}

			&:hover {
				background: darken(white, 7.5%);
				transform: translateY(-2.5%) !important;
			}

			+ .quiz {
				margin-top: 6px;
			}

			.cover-img {
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				width: 80%;
				z-index: 0;

				&::after {
					background: linear-gradient(to right, darken(white, 5%) 10%, transparent);
					content: '';
					display: block;
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					transition: $transition;
					width: 100%;
				}

				img {
					border-radius: $border-radius;
					height: 100%;
					opacity: 0.5;
					object-fit: cover;
					width: 100%;
				}
			}

			.date {
				font-weight: 600;
				position: relative;
				text-align: left;
				width: 70px;
				z-index: 1;
			}

			::v-deep.user {
				margin-right: 12px;

				.avatar {
					cursor: pointer !important;
				}
			}

			.title {
				position: relative;
				z-index: 1;
			}

			.action {
				margin-left: auto;
				position: relative;
				z-index: 1;
			}
		}
	}
}
</style>
